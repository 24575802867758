//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
.question {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  padding: 16px 0;
}
.answer {
  margin-top: 6px;
}
.question strong .iconquestion {
  width: 27px;
  height: 27px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #009ef7;
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  margin-right: 3px;
}
